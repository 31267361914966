<template>
  <div class=" mb-2">
    <ul class="list-inline">
      <li
        v-for="(feature, index) in features"
        :key="index"
        class="mb-3"
      >
        <i class="eva eva-checkmark-outline mr-2 text-warning"></i>
        {{ feature.content }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'shortFeatures',

  components: {},

  data() {
    return {
      features: [
        {
          content: 'Awesome neon style'
        },
        {
          content: 'Many different block colors and types'
        },
        {
          content: 'Relaxing music and cool sounds'
        }
      ]
    };
  }
};
</script>

<style scoped>

</style>
