<template>
  <!-- solution start -->
  <section id="about-game" class="service-three p-t-b-80">
    <b-container class="">
      <b-row>
        <b-col md="12" class="section-header mb-5 animate__animated animate__slideInLeft">
          <h2 class="font-weight-bold">About game</h2>

          <p class="">
            Slide blocks and blast lines. Use buttons to crush some blocks and blast line.
          </p>
        </b-col>

        <b-col
          md="6"
          lg="4"
          v-for="(serviceFour, key) in servicesFour"
          :key="key"
          class=" mb-4"
          :class="[key >= 3 ? 'mb-lg-0' : '']"
        >
          <b-card no-body class="service-three--hover o-hidden animate__animated animate__fadeIn animate__slow">
            <div class="card-header p-0 text-center">
              <img class="img-fluid " :src="serviceFour.image" alt=""/>
            </div>

            <b-card-body class="">
              <h5 class="card-title mb-2 font-weight-bold">
                {{ serviceFour.name }}
              </h5>
              <p class="card-text">
                {{ serviceFour.desc }}
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  data() {
    return {
      servicesFour: [
        {
          name: 'Move',
          image: require('@/assets/games/dropNeonBlocks/screenshots/move.jpg'),
          desc: 'Move the neon blocks to make full lines'
        },
        {
          name: 'Multiply',
          image: require('@/assets/games/dropNeonBlocks/screenshots/combo.jpg'),
          desc: 'The more lines you explode the higher score you get'
        },
        {
          name: 'Unfreeze blocks',
          image: require('@/assets/games/dropNeonBlocks/screenshots/unfreeze.jpg'),
          desc: 'Unfreeze blocks – make a line including them'
        },
        {
          name: 'Ability "Hand"',
          image: require('@/assets/games/dropNeonBlocks/screenshots/hand.jpg'),
          desc: 'The button “hand” moves any block to the spot you need (except the frozen ones)'
        },
        {
          name: 'Ability "Hammer"',
          image: require('@/assets/games/dropNeonBlocks/screenshots/hammer.jpg'),
          desc: 'The button “hammer” crushes a regular or frozen neon block'
        },
        {
          name: 'Ability "Sword"',
          image: require('@/assets/games/dropNeonBlocks/screenshots/sword.jpg'),
          desc: 'The button “sword” blasts the entire line'
        }
      ]
    };
  }
};
</script>

<style>
#about-game {
  padding: 40px 0;
}
</style>
