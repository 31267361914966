<template>
  <div>
    <navbar/>

    <dx-header/>

    <screenshots/>

    <features/>

    <dx-footer/>
  </div>
</template>

<script>
import footer from './../../components/shared/footer';
import header from './../../components/games/dropNeonBlocks/header';
import navbar from './../../components/games/dropNeonBlocks/navbar';
import features from './../../components/games/dropNeonBlocks/features';
import screenshots from './../../components/games/dropNeonBlocks/screenshots';

export default {
  metaInfo: {
    bodyAttrs: {
      class: ['landing-gradient-purple-indigo']
    }
  },

  name: 'DropNeonBlocks',

  components: {
    dxHeader: header,
    features,
    screenshots,
    navbar,
    dxFooter: footer
  },

  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>

</style>
