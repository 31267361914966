<template>
  <div class="main-header header-fixed-default" id="home-header">
    <b-navbar
      class="navbar container w-100 navbar-expand-lg "
      toggleable="lg"
      type=""
      variant=""
    >
      <b-navbar-brand href="/">
        <div class="logo">
          <img src="@/assets/images/landing/lOGO_d/logo_white.png" alt=""/>
        </div>
      </b-navbar-brand>

      <b-navbar-toggle
        target="nav-collapse"
        class="eva eva-menu-outline text-18 text-white"
      />

      <b-collapse id="nav-collapse" is-nav>
        <div style="margin: auto"></div>

        <b-navbar-nav class="header-part-right">

          <li class="nav-item">
            <router-link to="/" class="m-2">
              Speacletree
            </router-link>
          </li>

          <li class="nav-item">
            <a href="#about-game" v-smooth-scroll class="m-2">
              About game
            </a>
          </li>

          <li class="nav-item">
            <a href="#advantages" class="m-2" v-smooth-scroll>
              Advantages
            </a>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link: {
        hash: ['#testimonials-wrap']
      }
    };
  },
  methods: {
    handleScroll() {
      var scroll = window.pageYOffset;
      if(scroll >= 80) {
        document.querySelector('.main-header')
          .classList
          .add('header-fixed');
      } else {
        document.querySelector('.main-header')
          .classList
          .remove('header-fixed');
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style>
</style>
