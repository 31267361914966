<template>
  <a :href="link" class="btn btn-store mr-2 mt-2" target="_blank">
    <span class="fa fa-android fa-3x pull-left"></span>
    <span class="btn-label">Download on the</span>
    <span class="btn-caption">Google Play</span>
  </a>
</template>

<script>
export default {
  name: 'GooglePlayButton',

  props: {
    link: String
  }
};
</script>

<style scoped>
.btn-store {
  color: #ffffff;
  min-width: 254px;
  padding: 12px 20px !important;
  border-color: #dddddd !important;
}

.btn-store:focus,
.btn-store:hover {
  color: #ffffff !important;
  background-color: #168eea;
  border-color: #168eea !important;
}

.btn-store .btn-label,
.btn-store .btn-caption {
  display: block;
  text-align: left;
  line-height: 1;
}

.btn-store .btn-caption {
  font-size: 24px;
}
</style>
