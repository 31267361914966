<template>
  <section
    id="intro-wrap"
    class="intro-nine-Wrap bg-primary text-left text-white"
  >
    <b-container>
      <b-row>
        <b-col md="6" class=" intro-nine-LeftSection pb-3 animate__animated animate__slideInLeft">
          <h1 class="font-weight-bold text-white text-24 mb-3 t-shadow">
            DROP NEON BLOCKS
          </h1>

          <div class=" mb-2 lead">
            <short-features/>
          </div>

          <div class="intro-nine-Buttons mb-3">
            <google-play-button
              link="https://play.google.com/store/apps/details?id=com.speancletree.dropneonblocks.puzzlegame"
            />
          </div>
        </b-col>

        <b-col
          md="5"
          class="offset-md-1 intro-nine-RightSection d-flex align-items-center animate__animated animate__slideInRight"
        >
          <div class="intro-nine-ProductImage">
            <img
              src="@/assets/home/headerGame.png"
              class="img-responsive"
              alt="img-intro"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="overlay"></div>
  </section>
</template>

<script>
import GooglePlayButton from '../shared/GooglePlayButton';
import shortFeatures from './header/shortFeatures';

export default {
  components: {
    shortFeatures,
    GooglePlayButton
  }
};
</script>

<style>
.intro-nine-ProductImage {
  width: 200px;
}
</style>
