<template>
  <section id="advantages" class="features-five p-t-b-80 light-gray">
    <b-container>
      <b-row>

        <b-col lg="5" class="mb-5 animate__animated animate__slideInLeft">
          <h2 class="font-weight-bold">{{ heading }}</h2>
          <p>{{ description }}</p>

          <b-row>
            <b-col
              md="12"
              class="mt-4"
              v-for="(featureThree, key) in featuresThree"
              :key="key"
            >
              <div class="benefits-contents d-flex">
                <i :class="featureThree.icon" class="eva mr-2 text-28"></i>
                <div class="benefits-contents__info">
                  <h3 class="card-title font-weight-bold">
                    {{ featureThree.title }}
                  </h3>

                  <span class="text-15">
                    {{ featureThree.desc }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          lg="4"
          offset-lg="2"
          cols="8"
          offset="2"
          md="6"
          offset-md="3"
          class=" d-flex align-items-center animate__animated animate__slideInRight"
        >
          <div class="benefits-left-img mb-4 ">
            <img src="@/assets/games/dropNeonBlocks/tabletPhone.png" alt=""/>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {

  data() {
    return {
      heading: 'Advantages',

      description: '',

      featuresThree: [
        {
          title: 'Easy to play',
          icon: 'eva-arrow-right',
          desc: ''
        },
        {
          title: 'Two sizes of playing fields',
          icon: 'eva-monitor-outline',
          desc: ''
        },
        {
          title: 'Special blocks: frozen or multicolored',
          icon: 'eva-star-outline',
          desc: ''
        },
        {
          title: 'Helpful abilities',
          icon: 'eva-shield-outline',
          desc: ''
        },
        {
          title: 'Relaxing space music',
          icon: 'eva-music-outline',
          desc: ''
        }
      ]
    };
  }
};
</script>

<style>
#features {
  padding: 40px 0;
}
</style>
